import React, { MouseEvent, ReactNode, useRef, useState } from 'react';
import { Link, To } from 'react-router-dom';
import {
    arrow,
    autoPlacement,
    offset,
    safePolygon,
    size,
    useFloating,
    useHover,
    useInteractions,
} from '@floating-ui/react';
import { ClassValue, clsx } from 'clsx';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faAwardSimple, faChevronRight } from '@fortawesome/pro-light-svg-icons';
import { flushSync } from 'react-dom';
import { twMerge } from 'tailwind-merge';

import { CompanyLogo } from '../CompanyLogo';
import { ChipNumber } from '../ChipNumber';

export interface LinkWrapperProps {
    href?: string;
    to?: To;
    target?: string;
    onClick?: (ev: MouseEvent<HTMLAnchorElement>) => void;
    children?: ReactNode;
    dataTestid?: string;
    className?: string;
}

function LinkWrapper({ to, href, target, onClick, children, dataTestid, className }: LinkWrapperProps) {
    if (to)
        return (
            <Link
                to={to}
                className={className}
                data-testid={dataTestid}
            >
                {children}
            </Link>
        );

    if (href)
        return (
            <a
                href={href}
                target={target}
                className={className}
                data-testid={dataTestid}
                onClick={onClick}
            >
                {children}
            </a>
        );
    else
        return (
            <a
                className={className}
                data-testid={dataTestid}
                onClick={onClick}
            >
                {children}
            </a>
        );
}

export interface NavItemProps {
    disabled?: boolean;
    isActive?: boolean;
    isProFeature?: boolean;
    href?: string;
    to?: To;
    target?: string;
    onClick?: (ev: MouseEvent<HTMLAnchorElement>) => void;
    title: string;
    className?: ClassValue;
    children?: ReactNode;
    icon?: React.ReactElement<typeof FontAwesomeIcon>;
    hasRoundedIcon?: boolean;
    bottomDivider?: boolean;
    dataTestid?: string;
    htmlAttributes?: React.HTMLAttributes<HTMLLIElement>;
    logoUrl?: string;
    countValue?: number;
}

export const NavItem = ({
    disabled = false,
    isProFeature = false,
    isActive = false,
    title,
    className,
    children,
    icon,
    hasRoundedIcon = false,
    bottomDivider = false,
    dataTestid = 'nav-item',
    logoUrl = undefined,
    countValue = undefined,
    onClick,
    to,
    href,
    target,
    htmlAttributes,
}: NavItemProps) => {
    const [isOpen, setIsOpen] = useState(false);
    const [maxHeight, setMaxHeight] = useState<number | null>(null);
    const arrowRef = useRef(null);

    const { refs, floatingStyles, context } = useFloating({
        open: isOpen,
        onOpenChange: setIsOpen,
        placement: 'right-start',
        strategy: 'fixed',
        middleware: [
            autoPlacement({ alignment: 'start', allowedPlacements: ['right-start', 'right-end'] }),
            offset({ mainAxis: 15, alignmentAxis: -8 }),
            size({
                apply({ availableHeight }) {
                    flushSync(() => setMaxHeight(availableHeight - 10));
                },
            }),
            arrow({
                element: arrowRef,
            }),
        ],
    });

    const hover = useHover(context, {
        delay: { open: 75 },
        enabled: !!children,
        handleClose: safePolygon({
            blockPointerEvents: true, // Avoid hover events on the sibling elements during move to be triggered
        }),
    });
    const { getReferenceProps, getFloatingProps } = useInteractions([hover]);

    return (
        <li
            className={clsx('flex-1 cursor-pointer list-none', className)}
            {...htmlAttributes}
        >
            <div
                ref={refs.setReference}
                {...getReferenceProps()}
            >
                <LinkWrapper
                    dataTestid={dataTestid}
                    className={twMerge(
                        clsx(
                            'flex h-12 items-center gap-2 whitespace-nowrap px-2 py-3.5 text-sm text-white',
                            'hover:bg-zinc-800 hover:font-normal hover:text-lime-300',
                            {
                                'text-zinc-500 cursor-not-allowed': disabled,
                                'cursor-not-allowed': isProFeature,
                                'rounded-md': true,
                                'bg-zinc-800 text-lime-300': isOpen && children,
                                'text-black bg-lime-300 hover:bg-lime-300 hover:text-black': isActive,
                                'relative mb-4 after:-bottom-2 after:content-[""] after:absolute after:border-b after:border-solid after:border-zinc-600 after:left-0 after:right-0':
                                    bottomDivider,
                            }
                        )
                    )}
                    to={to}
                    href={href}
                    target={target}
                    onClick={onClick}
                >
                    {logoUrl && (
                        <CompanyLogo
                            logoUrl={logoUrl}
                            fromNavBar
                            isActive={isActive}
                        />
                    )}
                    {icon && !logoUrl && (
                        <div
                            className={clsx({
                                'bg-zinc-700 rounded-full p-2 h-8 w-8 text-white flex': hasRoundedIcon,
                            })}
                        >
                            {icon}
                        </div>
                    )}
                    {title}
                    {isProFeature && (
                        <FontAwesomeIcon
                            icon={faAwardSimple}
                            className='text-violet-400'
                        />
                    )}
                    {countValue !== undefined && (
                        <ChipNumber
                            variant={'dark'}
                            value={countValue}
                            hasBadge={true}
                        />
                    )}
                    {children && (
                        <FontAwesomeIcon
                            icon={faChevronRight}
                            className='ml-auto'
                        />
                    )}
                </LinkWrapper>
            </div>

            {isOpen && children && (
                <div
                    ref={refs.setFloating}
                    className='flex rounded-md bg-zinc-900 p-2'
                    style={{ ...floatingStyles, ...(maxHeight ? { maxHeight } : {}) }}
                    {...getFloatingProps()}
                    onClick={() => setIsOpen(false)}
                >
                    <div className='overflow-y-auto'>{children}</div>
                </div>
            )}
        </li>
    );
};
