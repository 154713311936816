import React from 'react';
import { Navigate } from 'react-router-dom';
import { NavigateWithParams } from '@spacefill/shared/src/utils/RouterUtils';

const LogisticManagementStockAdjustmentByWarehouse = async () =>
    import('../../pages/logistic-management/warehouses/inventory/stock-situation/new-adjustment/StockAdjustment').then(
        (module) => ({ Component: module.LogisticManagementStockAdjustmentByWarehouse })
    );
const LogisticManagementAccurateStockSituation = async () =>
    import('../../pages/logistic-management/inventory/accurate-stock-situation').then((module) => ({
        Component: module.LogisticManagementAccurateStockSituation,
    }));

const LogisticManagementOrdersRedirectView = async () =>
    import('../../pages/logistic-management/orders/RedirectView').then((module) => ({
        Component: module.LogisticManagementOrdersRedirectView,
    }));

const LogisticManagementOrdersImport = async () =>
    import('../../pages/logistic-management/warehouses/orders/new/ImportOrders').then((module) => ({
        Component: module.LogisticManagementOrdersImport,
    }));

const EntryOrdersList = async () =>
    import('../../pages/logistic-management/orders/EntryList').then((module) => ({
        Component: module.EntryOrdersList,
    }));
const ExitOrdersList = async () =>
    import('../../pages/logistic-management/orders/ExitList').then((module) => ({
        Component: module.ExitOrdersList,
    }));
const WarehousesEntryOrdersList = async () =>
    import('../../pages/logistic-management/warehouses/orders/EntryList').then((module) => ({
        Component: module.WarehousesEntryOrdersList,
    }));
const WarehousesExitOrdersList = async () =>
    import('../../pages/logistic-management/warehouses/orders/ExitList').then((module) => ({
        Component: module.WarehousesExitOrdersList,
    }));
const LogisticManagementUnconfirmedOrdersList = async () =>
    import('../../pages/logistic-management/unconfirmed-orders/List').then((module) => ({
        Component: module.LogisticManagementUnconfirmedOrdersList,
    }));
const LogisticManagementUnconfirmedOrdersView = async () =>
    import('../../pages/logistic-management/unconfirmed-orders/View').then((module) => ({
        Component: module.LogisticManagementUnconfirmedOrdersView,
    }));
const LogisticManagementWarehousesOrdersView = async () =>
    import('../../pages/logistic-management/warehouses/orders/view/View').then((module) => ({
        Component: module.LogisticManagementWarehousesOrdersView,
    }));
const NewOrderInit = async () =>
    import('../../pages/logistic-management/warehouses/orders/new/Init').then((module) => ({
        Component: module.NewOrderInit,
    }));
const LogisticManagementWarehousesNewOrdersEntry = async () =>
    import('../../pages/logistic-management/warehouses/orders/new/Entry').then((module) => ({
        Component: module.LogisticManagementWarehousesNewOrdersEntry,
    }));
const LogisticManagementWarehousesNewOrdersExit = async () =>
    import('../../pages/logistic-management/warehouses/orders/new/Exit').then((module) => ({
        Component: module.LogisticManagementWarehousesNewOrdersExit,
    }));
const LogisticManagementWarehousesOrdersAdjustments = async () =>
    import('../../pages/logistic-management/warehouses/orders/Adjustments').then((module) => ({
        Component: module.LogisticManagementWarehousesOrdersAdjustments,
    }));
const LogisticManagementWarehousesEditOrdersRedirectEntryOrExit = async () =>
    import('../../pages/logistic-management/warehouses/orders/edit/index').then((module) => ({
        Component: module.LogisticManagementWarehousesEditOrdersRedirectEntryOrExit,
    }));
const LogisticManagementWarehousesEditOrders = async () =>
    import('../../pages/logistic-management/warehouses/orders/edit/Edit').then((module) => ({
        Component: module.LogisticManagementWarehousesEditOrders,
    }));
const LogisticManagementMasterItemsListCustomUnit = async () =>
    import('../../pages/logistic-management/inventory/items/List').then((module) => ({
        Component: module.LogisticManagementMasterItemsList,
    }));
const LogisticManagementMasterItemsNewCustomUnit = async () =>
    import('../../pages/logistic-management/inventory/items/New').then((module) => ({
        Component: module.LogisticManagementMasterItemsNew,
    }));
const LogisticManagementMasterItemsEditCustomUnit = async () =>
    import('../../pages/logistic-management/inventory/items/Edit').then((module) => ({
        Component: module.LogisticManagementMasterItemsEdit,
    }));
const LogisticManagementMasterItemsViewCustomUnit = async () =>
    import('../../pages/logistic-management/inventory/items/View').then((module) => ({
        Component: module.LogisticManagementMasterItemsView,
    }));
const LogisticProviderDataView = async () =>
    import('../../pages/logistic-management/analytics/View/View').then((module) => ({ Component: module.DataView }));
const LogisticManagementDocumentsView = async () =>
    import('../../pages/logistic-management/documents/View').then((module) => ({
        Component: module.LogisticManagementDocumentsView,
    }));

const LogisticManagementBillingList = async () =>
    import('../../pages/logistic-management/warehouses/billing/List').then((module) => ({
        Component: module.LogisticManagementBillingList,
    }));
const LogisticManagementBillingView = async () =>
    import('../../pages/logistic-management/warehouses/billing/View').then((module) => ({
        Component: module.LogisticManagementBillingView,
    }));
const LogisticManagementBillingEdit = async () =>
    import('../../pages/logistic-management/warehouses/billing/Edit').then((module) => ({
        Component: module.LogisticManagementBillingEdit,
    }));

const getLogisticProviderLogisticManagementRoutes = (user) => {
    if (user.role !== 'PROVIDER' || (user?.customerWarehouseConfigurations ?? []).length === 0) {
        return [];
    }

    const IncidentsView = async () =>
        import('../../pages/logistic-management/incidents/list/List').then((module) => ({
            Component: module.IncidentsView,
        }));

    return [
        // Global routes

        //  Data View
        {
            path: '/logistic-management/analytics/',
            lazy: LogisticProviderDataView,
            condition: user.features.hasAnalyticsEnabled,
        },

        // Incidents View
        {
            path: '/logistic-management/incidents/',
            lazy: IncidentsView,
        },
        //  Documents
        {
            path: '/documents/',
            lazy: LogisticManagementDocumentsView,
        },
        // Orders
        {
            path: '/logistic-management/orders/',
            element: <NavigateWithParams to='/logistic-management/exit-orders/' />,
        },
        {
            path: '/logistic-management/entry-orders/',
            lazy: EntryOrdersList,
        },
        {
            path: '/logistic-management/exit-orders/',
            lazy: ExitOrdersList,
        },
        {
            path: '/logistic-management/entry-orders/init',
            lazy: NewOrderInit,
            conditionFailedRedirect: '/',
        },
        {
            path: '/logistic-management/exit-orders/init',
            lazy: NewOrderInit,
            conditionFailedRedirect: '/',
        },
        {
            path: '/logistic-management/orders/:orderId/',
            lazy: LogisticManagementOrdersRedirectView,
        },
        {
            path: '/logistic-management/orders/import/',
            lazy: LogisticManagementOrdersImport,
        },

        // Master items with Custom Unit
        {
            path: '/logistic-management/inventory/items/new',
            lazy: LogisticManagementMasterItemsNewCustomUnit,
        },
        {
            path: '/logistic-management/inventory/items/:masterItemId/edit',
            lazy: LogisticManagementMasterItemsEditCustomUnit,
        },
        {
            path: '/logistic-management/inventory/items/:masterItemId/',
            lazy: LogisticManagementMasterItemsViewCustomUnit,
        },
        {
            path: '/logistic-management/inventory/items/',
            lazy: LogisticManagementMasterItemsListCustomUnit,
        },

        // Stock situation
        {
            path: '/logistic-management/inventory/stock-situation/references/',
            lazy: LogisticManagementAccurateStockSituation,
        },
        {
            path: '/logistic-management/inventory/stock-situation/references/forecasted/',
            lazy: LogisticManagementAccurateStockSituation,
        },
        {
            path: '/logistic-management/inventory/stock-situation/references/accurate/',
            lazy: LogisticManagementAccurateStockSituation,
        },
        {
            path: '/logistic-management/inventory/stock-situation/references/accurate/forecasted/',
            lazy: LogisticManagementAccurateStockSituation,
        },

        // By warehouse

        // Orders
        {
            path: '/logistic-management/unconfirmed-orders/',
            lazy: LogisticManagementUnconfirmedOrdersList,
        },
        {
            path: '/logistic-management/unconfirmed-orders/:emailId/',
            lazy: LogisticManagementUnconfirmedOrdersView,
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/orders/',
            element: (
                <NavigateWithParams
                    to={(params) => `/logistic-management/warehouses/${params.warehouseId}/exit-orders/`}
                />
            ),
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/entry-orders/',
            lazy: WarehousesEntryOrdersList,
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/exit-orders/',
            lazy: WarehousesExitOrdersList,
        },

        {
            path: '/logistic-management/warehouses/:warehouseId/entry-orders/init',
            lazy: NewOrderInit,
            conditionFailedRedirect: '/',
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/entry-orders/new',
            lazy: LogisticManagementWarehousesNewOrdersEntry,
            conditionFailedRedirect: '/',
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/exit-orders/init',
            lazy: NewOrderInit,
            conditionFailedRedirect: '/',
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/exit-orders/new',
            lazy: LogisticManagementWarehousesNewOrdersExit,
            conditionFailedRedirect: '/',
        },

        {
            path: '/logistic-management/warehouses/:warehouseId/orders/new-entry',
            lazy: LogisticManagementWarehousesNewOrdersEntry,
            conditionFailedRedirect: '/',
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/orders/new-exit',
            lazy: LogisticManagementWarehousesNewOrdersExit,
            conditionFailedRedirect: '/',
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/orders/:orderId/',
            lazy: LogisticManagementOrdersRedirectView,
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/entry-orders/:orderId/',
            lazy: LogisticManagementWarehousesOrdersView,
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/exit-orders/:orderId/',
            lazy: LogisticManagementWarehousesOrdersView,
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/entry-orders/:orderId/order-adjustment',
            lazy: LogisticManagementWarehousesOrdersAdjustments,
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/exit-orders/:orderId/order-adjustment',
            lazy: LogisticManagementWarehousesOrdersAdjustments,
        },

        // Edit order
        {
            path: '/logistic-management/warehouses/:warehouseId/orders/:orderId/edit',
            lazy: LogisticManagementWarehousesEditOrdersRedirectEntryOrExit,
        },

        {
            path: '/logistic-management/warehouses/:warehouseId/exit-orders/:orderId/edit',
            lazy: LogisticManagementWarehousesEditOrders,
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/entry-orders/:orderId/edit',
            lazy: LogisticManagementWarehousesEditOrders,
        },

        // Stock situation
        {
            path: '/logistic-management/warehouses/:warehouseId/inventory/stock-situation/references/',
            lazy: LogisticManagementAccurateStockSituation,
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/inventory/stock-situation/references/forecasted',
            lazy: LogisticManagementAccurateStockSituation,
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/inventory/stock-situation/references/last-snapshot',
            lazy: LogisticManagementAccurateStockSituation,
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/inventory/stock-situation/references/accurate/',
            lazy: LogisticManagementAccurateStockSituation,
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/inventory/stock-situation/references/accurate/forecasted',
            lazy: LogisticManagementAccurateStockSituation,
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/inventory/stock-situation/references/accurate/last-snapshot',
            lazy: LogisticManagementAccurateStockSituation,
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/inventory/stock-situation/new-adjustment/',
            lazy: LogisticManagementStockAdjustmentByWarehouse,
        },
        // Billing invoices
        {
            path: '/logistic-management/warehouses/:warehouseId/billing/',
            lazy: LogisticManagementBillingList,
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/billing/:customerWarehouseConfigurationId/',
            lazy: LogisticManagementBillingList,
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/billing/:customerWarehouseConfigurationId/invoices/',
            element: (
                <NavigateWithParams
                    to={(params) =>
                        `/logistic-management/warehouses/${params.warehouseId}/billing/${params.customerWarehouseConfigurationId}/`
                    }
                />
            ),
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/billing/:customerWarehouseConfigurationId/invoices/:invoiceId/',
            lazy: LogisticManagementBillingView,
        },
        {
            path: '/logistic-management/warehouses/:warehouseId/billing/:customerWarehouseConfigurationId/invoices/:invoiceId/edit',
            lazy: LogisticManagementBillingEdit,
        },

        // Default
        {
            path: '/logistic-management/*',
            element: <Navigate to='/' />,
        },
    ];
};

export { getLogisticProviderLogisticManagementRoutes };
